nav {
  height: 100vh;
  border-right: solid 3px #55efc4;

  a {
    display: block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background: #74b9ff;
    color: white;

    font-size: 18px;
    font-weight: bold;

    margin: 20px 0;

    &.selected {
      background: #0984e3;
      box-shadow: 0 17px 10px -10px rgba(0, 0, 0, 0.4);
    }
  }
}
